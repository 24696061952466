.timeContainer {
  width: 100%;
}

.inputContainer {
  display: flex;
}

.inputText {
  font-size: 60px;
  padding: 5px;
  margin: 1px;
  border: 0px;
  user-select: all;
  -webkit-user-select: all;
}

.inputText:focus {
  outline: none;
}

.time {
  width: 50%;
  text-align: right;
}

.timeZone {
  font-size: 40px;
  text-align: left;
  align-self: flex-end;
  padding-bottom: 12px;
  width: 50%;
}

.displayContainer {
  text-align: center;
}
