.displayContent {
  font-size: 20px;
  color: black;
}

.invalidTime {
  color: red;
}

.localTimeContainer {
  display: flex;
}

.localTime {
  padding-right: 5px;
  text-align: right;
  align-self: flex-end;
  font-size: 60px;
  flex-basis: 50%;
}

.localTimeZone {
  padding-left: 5px;
  text-align: left;
  align-self: flex-end;
  font-size: 40px;
  flex-basis: 50%;
  padding-bottom: 7px;
}
