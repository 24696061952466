* {
  box-sizing: border-box;
  font-family: sans-serif;
}

body {
  padding: 50px 0 50px 25px;
}

.timeContainer {
  width: 100%;
}

.inputContainer {
  display: flex;
}

.inputText {
  -webkit-user-select: all;
  user-select: all;
  border: 0;
  margin: 1px;
  padding: 5px;
  font-size: 60px;
}

.inputText:focus {
  outline: none;
}

.time {
  text-align: right;
  width: 50%;
}

.timeZone {
  text-align: left;
  align-self: flex-end;
  width: 50%;
  padding-bottom: 12px;
  font-size: 40px;
}

.displayContainer {
  text-align: center;
}

.displayContent {
  color: #000;
  font-size: 20px;
}

.invalidTime {
  color: red;
}

.localTimeContainer {
  display: flex;
}

.localTime {
  text-align: right;
  flex-basis: 50%;
  align-self: flex-end;
  padding-right: 5px;
  font-size: 60px;
}

.localTimeZone {
  text-align: left;
  flex-basis: 50%;
  align-self: flex-end;
  padding-bottom: 7px;
  padding-left: 5px;
  font-size: 40px;
}

/*# sourceMappingURL=index.c481f474.css.map */
